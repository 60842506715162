import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends ApiService {
  constructor() {
    super();
  }

  getUserDetails$(): Observable<UserDto> {
    return this.get(`/user/details`);
  }

  saveUserDetails$(data: PostUserDto): Observable<UserDto> {
    return this.post(`/user/details`, data);
  }

  inviteUser$(): Observable<UserDto> {
    return this.post(`/user/invite`);
  }
}

export interface UserDto {
  id: number;
  name: string;
  email: string;
  fullName: string;
  organizationName: string;
  organizationId: number;
  defaultInterfaceLanguageId?: number;
  defaultContentLanguageId?: number;
}

export interface PostUserDto {
  fullName: string;
  organizationName: string;
  defaultInterfaceLanguageId?: number;
  defaultContentLanguageId?: number;
}

export const apiUserProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiUser.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiUser.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiUser.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiUser.scopes.write],
  },
];
