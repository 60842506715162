import { TokensModel } from 'src/app/core/models/auth.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { UserDto } from 'src/app/modules/login/services/user-api.service';
import { AuthType } from 'src/app/modules/login/auth-green.service';

export interface AppState {
  user: UserDto | null;
  tokens: TokensModel | null;
  greenAuthUser: any | null;
  authType: AuthType | null;
  authCode: string | null;
  licenseExpired: boolean | null;
  loginError: HttpErrorResponse | null;
  onlyEmailAuthOption: boolean | null;
  loginning: boolean;
  inviting: boolean;
  gettingUserDetails: boolean;
  savingUserDetails: boolean;
  isLoggedIn: boolean;
  authenticationResult: AuthenticationResult | null;
  authenticatedAccount: AccountInfo | null;

  clauseNameToBeFiltered: string | null;
}

export const initialAppState: AppState = {
  user: null,
  tokens: null,
  greenAuthUser: null,
  authType: null,
  authCode: null,
  licenseExpired: null,
  loginError: null,
  onlyEmailAuthOption: null,
  loginning: false,
  inviting: false,
  gettingUserDetails: false,
  savingUserDetails: false,
  isLoggedIn: false,
  authenticationResult: null,
  authenticatedAccount: null,

  clauseNameToBeFiltered: null,
};
