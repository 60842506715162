import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, from, Observable } from 'rxjs';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { ApplicationName, ApplicationPaths } from './api-authorization.constants';
import { environment } from 'src/environment/environment';
import * as _ from 'lodash';
import { AuthActions } from 'src/app/core/store/actions';
import { INavigationState } from './components/login.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGreenService {
  private popUpDisabled = true;
  private userSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private manager = new UserManager(getClientSettings());

  public isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(
      map((user) => {
        return !!user;
      }),
    );
  }

  public getUser(): Observable<IUser | null> {
    const user = concat(
      this.userSubject.pipe(
        take(1),
        filter((user) => !!user),
      ),
      this.getUserFromStorage().pipe(
        filter((user) => !!user),
        tap((u) => this.userSubject.next(u)),
      ),
      this.userSubject.asObservable(),
    );

    return user;
  }

  public getAccessToken(): Observable<string | null> {
    const activeAccount = this.manager.getUser();
    const requestConfig = { ...environment.loginRequest, account: activeAccount };

    return from(this.ensureUserManagerInitialized()).pipe(
      mergeMap(() => from(this.manager.getUser())),
      map((user) => user && user.access_token),
    );
  }

  private getUserFromStorage(): Observable<IUser | null> {
    return from(this.ensureUserManagerInitialized()).pipe(
      mergeMap(() => this.manager.getUser()),
      map((u) => u && u.profile),
    );
  }

  public async signIn(state: any, store$: any): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();
    let user: any = null;

    try {
      user = await this.manager.signinSilent(this.createArguments());
      this.userSubject.next(user.profile);

      if (!!user) {
        store$.dispatch(AuthActions.setGreenAuthUser({ user }));
      }
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log('Silent authentication error: ', silentError);

      try {
        if (this.popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'authorize.service.ts:AuthorizeService.popupDisabled' to false to enable it.",
          );
        }
        user = await this.manager.signinPopup(this.createArguments());
        this.userSubject.next(user.profile);
        return this.success(state);
      } catch (popupError) {
        if (_.get(popupError, 'message') === 'Popup window closed') {
          // The user explicitly cancelled the login action by closing an opened popup.
          return this.error('The user closed the window.');
        } else if (!this.popUpDisabled) {
          console.log('Popup authentication error: ', popupError);
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.manager.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          console.log('Redirect authentication error: ', redirectError);
          return this.error(redirectError);
        }
      }
    }
  }

  public async completeSignIn(url: string, store$: any): Promise<IAuthenticationResult> {
    try {
      await this.ensureUserManagerInitialized();
      const user = await this.manager.signinCallback(url);
      this.userSubject.next(user && user.profile);

      if (!!user) {
        store$.dispatch(AuthActions.setGreenAuthUser({ user }));
      }

      return this.success(user && user.state);
    } catch (error) {
      console.log('There was an error signing in: ', error);
      return this.error('There was an error signing in.');
    }
  }

  public async signOutCalledFromAddIn(): Promise<any> {
    const state: INavigationState = { returnUrl: ApplicationPaths.LoggedOut };
    return this.manager.signoutRedirect(this.createArguments(state));
    //return this.manager.signoutPopup(this.createArguments());
  }

  public async signOut(state: any): Promise<IAuthenticationResult> {
    try {
      if (this.popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'authorize.service.ts:AuthorizeService.popupDisabled' to false to enable it.",
        );
      }
      await this.ensureUserManagerInitialized();
      await this.manager.signoutPopup(this.createArguments());

      this.userSubject.next(null);
      return this.success(state);
    } catch (popupSignOutError) {
      console.log('Popup signout error: ', popupSignOutError);
      try {
        await this.manager.signoutRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectSignOutError) {
        console.log('Redirect signout error: ', redirectSignOutError);
        return this.error(redirectSignOutError);
      }
    }
  }

  public async completeSignOut(url: string): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();
    try {
      const response = await this.manager.signoutCallback(url);
      this.userSubject.next(null);
      return this.success(response && response.state);
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  private createArguments(state?: any): any {
    return { useReplaceToNavigate: true, data: state };
  }

  private error(message: any): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  private success(state: any): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Success, state };
  }

  private redirect(): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Redirect };
  }

  private async ensureUserManagerInitialized(): Promise<void> {
    if (this.manager !== undefined) {
      return;
    }

    const settings: any = getClientSettings();
    settings.automaticSilentRenew = true;
    settings.includeIdTokenInSilentRenew = true;
    this.manager = new UserManager(settings);

    this.manager.events.addUserSignedOut(async () => {
      await this.manager.removeUser();
      this.userSubject.next(null);
    });

    this.manager.events.addAccessTokenExpiring(async () => {
      await this.manager.revokeAccessToken();
    });
  }
}

export function getClientSettings(): UserManagerSettings {
  let settings = {
    authority: environment.authServerUrl,
    client_id: ApplicationName,
    response_type: 'code',
    scope: 'openid offline_access',
    silentRequestTimeout: 2000,
    redirect_uri:
      location.hostname === 'localhost'
        ? `https://localhost:4400/${ApplicationPaths.LoginCallback}`
        : `${environment.appBaseUrl}/${ApplicationPaths.LoginCallback}`,
    post_logout_redirect_uri:
      location.hostname === 'localhost'
        ? `https://localhost:4400/${ApplicationPaths.Login}`
        : `${environment.appBaseUrl}/${ApplicationPaths.Login}`,
    filterProtocolClaims: true,
    loadUserInfo: true,
    silent_redirect_uri:
      location.hostname === 'localhost'
        ? `https://localhost:4400/assets/silent-renew.html`
        : `${environment.appBaseUrl}/assets/silent-renew.html`,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 4,
  };

  return settings;
}

export enum AuthType {
  MSAL = 1,
  EMAIL = 2,
}

export interface IUser {
  name?: string;
}

export type IAuthenticationResult =
  | SuccessAuthenticationResult
  | FailureAuthenticationResult
  | RedirectAuthenticationResult;

export interface SuccessAuthenticationResult {
  status: AuthenticationResultStatus.Success;
  state: any;
}

export interface FailureAuthenticationResult {
  status: AuthenticationResultStatus.Fail;
  message: string;
}

export interface RedirectAuthenticationResult {
  status: AuthenticationResultStatus.Redirect;
}

export enum AuthenticationResultStatus {
  Success,
  Redirect,
  Fail,
}
